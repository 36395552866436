import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, user }) => {
  if (!user) {
    // If user is not logged in, redirect to the login page
    return <Navigate to="/login" />;
  } else if (user.rol !== 'EDITOR') {
    // If user is not an EDITOR, redirect to a different page (e.g., home page)
    return <Navigate to="/" />;
  }

  // If user is logged in and has the EDITOR role, render the children components
  return children;
};

export default ProtectedRoute;
