import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs, Typography, TextField, Button, FormControl, FormGroup, FormControlLabel, InputLabel, Select, Switch, MenuItem, Container, Alert, List, ListItem, ListItemText, IconButton, Collapse, Snackbar } from '@mui/material';
import api from '../context/api';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const UserManagement = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const user = JSON.parse(localStorage.getItem('user'));

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="sm" sx={{ minHeight: '100vh', minWidth: '100%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <DrawerHeader/>
      <Box sx={{ 
        width: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center'
      }}>
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Change Password" />
          <Tab label="Change Role" />
          <Tab label="Manage Locations" />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <ChangePasswordForm />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {user.rol !== 'EDITOR' ?
            <Alert severity="error" sx={{ margin: 2 }}>
              You do not have the rights to access this page.
            </Alert>
            :
            <ChangeRoleForm />
          }
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          {user.rol !== 'EDITOR' ?
            <Alert severity="error" sx={{ margin: 2 }}>
              You do not have the rights to access this page.
            </Alert>
            :
            <ManageLocationsForm />
          }
        </TabPanel>
      </Box>
    </Container>
  );
};

const ChangePasswordForm = () => {

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
    });

    const handleInputChange = (field, value) => {
        // Update the form data state
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field]: value
        }));
    };

    const onSubmit = async () => {
      const token = localStorage.getItem('token'); 
      try {
        const response = await api.patch('/change-password', formData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
        });

        if(response.status === 202){
            // Set success message
            setSuccess(response.data.message || 'Password changed successfully!');
            setError(''); // Clear any previous errors
        } else {
            // Set error message
            setError('Unexpected response from the server.');
            setSuccess(''); // Clear any previous success message
        }

      } catch (error) {
        setError(error.response?.data?.message || 'An error occurred while changing the password.');
        setSuccess(''); // Clear any previous success message
        console.error("An error occurred during password change:", error);
      }
    }

    return (
        <Box component="form" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            '& .MuiTextField-root': { m: 1, width: '25ch' }, 
        }}>
            <TextField label="Current Password" type="password" 
                value={formData['current_password']} variant="outlined" 
                onChange={(e) => handleInputChange('current_password', e.target.value)}
            />
            <TextField label="New Password" type="password" variant="outlined" 
                value={formData['new_password']}
                onChange={(e) => handleInputChange('new_password', e.target.value)}
            />
            <TextField label="Confirm New Password" type="password" variant="outlined" 
                value={formData['new_password_confirmation']} 
                onChange={(e) => handleInputChange('new_password_confirmation', e.target.value)}
            />
            <Button variant="contained" sx={{ mt: 2, width: '25ch' }} onClick={onSubmit} >Change Password</Button>
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                '& .MuiTextField-root': { m: 1, width: '25ch' }, 
                marginTop: 2
            }}>
                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">{success}</Alert>}
            </Box>
        </Box>
    );
};

const ChangeRoleForm = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [isEditor, setIsEditor] = useState(false); // Assuming 'false' means REVIEWER and 'true' means EDITOR

    const getUsers = async () => {
      const token = localStorage.getItem('token'); 
      try {
        const response = await api.get('/users', {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
        });

        if(response.status === 200){
            setUsers(response.data);
        } 

      } catch (error) {
        console.error(error);
      }finally{
        setIsLoading(false);
      }
    }

    const handleUserChange = (event) => {
        const userId = event.target.value;
        setSelectedUserId(userId);
        // Find the selected user and set the switch based on their role
        const selectedUser = users.find(user => user.id === userId);
        setIsEditor(selectedUser.rol === 'EDITOR');
    };

    const changeUserRole = async (newRole) => {
      const token = localStorage.getItem('token'); 
      try {
        const response = await api.patch('/change-rol/'+selectedUserId, { rol: newRole }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 202) {
          console.log('Role successfully changed.');
        }
      } catch (error) {
        console.error('An error occurred while changing the role:', error.response?.data?.message || error.message);
        // Handle errors, such as displaying a notification to the user
      }finally{
        getUsers();
      }
    };

    const handleRoleToggle = (event) => {
        const newIsEditor = event.target.checked;
        setIsEditor(newIsEditor); // Update the isEditor state based on the switch's position
        const newRole = newIsEditor ? 'EDITOR' : 'REVIEWER';
        changeUserRole(newRole);
    };

    useEffect(() => {
        getUsers();
    }, [])
    
    if (isLoading) {
        return <div>Loading...</div>;
    } else {
        return (
            <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel>User</InputLabel>
                       <Select
                        label="User"
                        onChange={handleUserChange}
                    >
                        {users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                {user.name}
                            </MenuItem>
                        ))}
                        </Select> 
                </FormControl>
                <FormGroup>
                    <FormControlLabel 
                        control={<Switch checked={isEditor} onChange={handleRoleToggle} />}
                        label={isEditor ? 'EDITOR' : 'REVIEWER'}
                    />
                </FormGroup> 
            </Box>
        );
    }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-management-tabpanel-${index}`}
      aria-labelledby={`user-management-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ManageLocationsForm = () => {
  const [locationName, setLocationName] = useState('');
  const [locations, setLocations] = useState([]); 
  const [open, setOpen] = useState(false); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info'); // 'success', 'error', 'warning', 'info'
  const token = localStorage.getItem('token');

  const toggleCollapse = () => {
    setOpen(!open); // Toggle the state
  };

  useEffect(() => {
      getLocations();
  }, [])
    
  const getLocations = async () => {
    try {
      const response = await api.get(`/data`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        const locations = response.data
          .filter(item => item.type === 'location')
          .map(item => item.value)
          .sort((a, b) => a.localeCompare(b));

          setLocations(locations);
      }
    } catch (error) {
      console.error('An error occurred during the file upload:', error);
    }
  }

  const handleAddLocation = async () => {
    try {
      const response = await api.post('/locations', { value: locationName }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 201) {
        const newLocation = locationName;
        setLocations(prevLocations => [...prevLocations, newLocation]);
        setLocationName(''); 
        setSnackbarMessage('Location added successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Failed to add location');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteLocation = async (name) => {
    try {
      const response = await api.delete(`/locations/${name}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setLocations(locations.filter(location => location !== name)); 
        setSnackbarMessage('Location deleted successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Failed to delete location');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ 
      mt: 2, 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', 
    }}>
      <TextField
        label="New Location"
        variant="outlined"
        fullWidth
        value={locationName}
        onChange={(e) => setLocationName(e.target.value)}
      />
      <Button variant="contained" onClick={handleAddLocation} sx={{ mt: 2 }}>
        Add Location
      </Button>
      <Button
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={toggleCollapse}
        sx={{ mt: 2 }}
      >
        {open ? 'Hide Locations' : 'Show Locations'}
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ mt: 2, width: '100%' }}>
          {locations.map((loc, index) => (
            <ListItem key={index} secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteLocation(loc)}>
                <DeleteIcon />
              </IconButton>
            }>
              <ListItemText primary={loc} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserManagement;
