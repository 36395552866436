import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, TextField, FormGroup, Box, Button, Typography, InputLabel } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '../assets/add.png';
import api from '../context/api';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Pie, PieChart, Cell, Bar, BarChart, Rectangle, Customized
} from 'recharts';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1210,
      lg: 1536,
      xl: 1920,
    },
  },
});

const Visualization = () => {

  const [id, setIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  var filters = {
    timelineType: 'annual',
    startPeriod: '2023-04-14',
    endPeriod: '2023-04-15',
    location: '',
    source: '',
    cause: '',
    injured: '',
    reference: '',
  };

  // Chart management
  const [charts, setCharts] = useState([{
    data: [],
    filters: filters,
    type: 'line',
    year: new Date().getFullYear(),
    comparisonYear: '',
    element: 'location',
    page: 1,
    totalPages: 1,
  }]);
  
  const addNewChart = () => {
    setIsLoading(true);
    setCharts(currentCharts => [
      ...currentCharts,
      {
        data: [],
        filters: { ...filters },
        type: 'line',
        year: new Date().getFullYear(),
        comparisonYear: '',
        element: 'location',
        page: 1,
        totalPages: 1,
      }
    ]);
    setChecked(currentChecked => [
      ...currentChecked,
      {
        annual: true,
        quarterly: false,
        '13periods': false,
        custom: false
      }
    ]);
    setCustomDates(currentDates => [
      ...currentDates,
      {
        startPeriod: '',
        endPeriod: ''
      }
    ]);
    //timeLine(charts.length - 1);
  };

  const tableHeaders = [
    { id: 'location', label: 'Location' },
    { id: 'source', label: 'Source' },
    { id: 'cause', label: 'Cause' },
    { id: 'injured', label: 'Injured' },
    { id: 'reference', label: 'Reference' },
  ];

  const [filterOptions, setFilterOptions] = useState({
    location: [],
    source: [],
    cause: [],
    injured: [],
    reference: [],
  });

  const handleFilterChange = (chartIndex, filterName, value) => {
    setCharts(currentCharts => currentCharts.map((chart, index) => {
      if (index === chartIndex) {
        return {
          ...chart,
          filters: {
            ...chart.filters,
            [filterName]: value
          }
        };
      }
      return chart;
    }));
    setIndex(chartIndex);
  };

  const [customDates, setCustomDates] = useState(charts.map(() => ({
    startPeriod: '',
    endPeriod: ''
  })));

  const handleDateChange = (index, event) => {
    const newDate = [...customDates];
    const { name, value } = event.target;
    newDate[index][name] = value;
    setCustomDates(newDate);
  };

  const onClickCustom = (index) => {
    const { startPeriod, endPeriod } = customDates[index];
    setCharts(currentCharts => currentCharts.map((chart, chartIndex) => {
      if (index === chartIndex) {
        return {
          ...chart,
          filters: {
            ...chart.filters,
            startPeriod: startPeriod,
            endPeriod: endPeriod,
            timelineType: 'custom'
          }
        };
      }
      return chart;
    }));
    setIndex(index);
  }

  const [checked, setChecked] = useState(charts.map(() => ({
    annual: true,
    quarterly: false,
    '13periods': false,
    custom: false
  })));

  const handleChange = (index, event) => {
    const newChecked = [...checked];
    // Reset all checkboxes
    newChecked[index] = {
      annual: false,
      quarterly: false,
      '13periods': false,
      custom: false
    };

    if(charts[index].timeLine !== 'annual'){
      charts[index].element = "location";
    }

    // Activate the clicked one
    newChecked[index][event.target.name] = event.target.checked;
    setChecked(newChecked);
    //setChecked({ ...newChecked, [event.target.name]: event.target.checked });

    // Reset custom dates if not custom
    if (event.target.name !== 'custom') {
      const newDate = [...customDates];
      newDate[index] = { startDate: '', endDate: '' };
      setCustomDates(newDate);
      handleFilterChange(index, 'timelineType', event.target.name);
    }
  };

  const handleYearChange = async (index, event) => {
    const year = event.target.value;
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return {
          ...chart,
          year: year
        };
      }
      return chart;
    }));
    setIndex(index);
  };

  const handleElementChange = async (index, element) => {
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return {
          ...chart,
          element: element
        };
      }
      return chart;
    }));
    setIndex(index);
  };

  const handleComparisonYearChange = (index, event) => {
    const year = event.target.value;
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return {
          ...chart,
          comparisonYear: year
        };
      }
      return chart;
    }));
    setIndex(index);
  };

  // Fetch data, process it according to timeline and period, and update state
  const timeLine = async (index) => {
    setIsLoading(true);
    const chart = charts[index];
    const isAnnualPieChart = chart.type === 'pie' && chart.filters.timelineType === 'annual';
    const perPage = isAnnualPieChart ? 50 : 5; 

    try {
      const response = await api.get(`/graph`, {
        params: { 
          ...chart.filters, 
          year: chart.year,
          comparisonYear: chart.comparisonYear,
          element: chart.element,
          page: chart.page,
          perPage: perPage,
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        console.log("response", response.data)
        setCharts(currentCharts => currentCharts.map((chart, idx) => {
          if (idx === index) {
            return {
              ...chart,
              data: response.data,
              totalPages: response.data.totalPages
            };
          }
          return chart;
        }));
      }

    } catch (err) {
      console.log("An error ocurred:", err);
    } finally {
      setIsLoading(false);
    }
  }

  const deleteChart = (chartIndex) => {
    // Update charts state
    setCharts(currentCharts => currentCharts.filter((_, index) => index !== chartIndex));

    // Update checked state
    setChecked(currentChecked => currentChecked.filter((_, index) => index !== chartIndex));

    // Update customDates state if needed
    setCustomDates(currentCustomDates => currentCustomDates.filter((_, index) => index !== chartIndex));
    setIndex(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/${user.id}/entry`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const options = {
          location: [...new Set(response.data.map(item => item.location))],
          source: [...new Set(response.data.map(item => item.source))],
          cause: [...new Set(response.data.map(item => item.cause))],
          date: [...new Set(response.data.map(item => item.date))],
          injured: [...new Set(response.data.map(item => item.injured))],
          reference: [...new Set(response.data.map(item => item.reference))],

        };
        setFilterOptions(options);
      }
    }

    fetchData();

    timeLine(0);
  }, []);

  useEffect(() => {
    if (id !== null) {
      timeLine(id);
      setIndex(null); // Reset the state after updating
    }
  }, [id]);

  useEffect(() => {
    const lastIndex = charts.length - 1;
    timeLine(lastIndex);
  }, [charts.length])

  // Graph to PDF
  const exportChartToPDF = async () => {
    const container = document.getElementById('recharts-container');

    // Use html2canvas to take a screenshot of the container
    const canvas = await html2canvas(container, {
      scale: 1, // Adjust scale if needed
      width: container.scrollWidth,
      height: container.scrollHeight,
      useCORS: true // If you're loading images from external domains
    });

    const imgData = canvas.toDataURL('image/png');

    // Use jsPDF to create a new PDF
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px', // Use 'px' to match html2canvas output
      format: [canvas.width, canvas.height] // Set the PDF page size to the canvas size
    });

    // Add the entire captured canvas to the PDF
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

    // Save the PDF
    pdf.save('chart-and-buttons.pdf');
  };

  const handleChangeChartType = (index, type) => {
    setCharts(currentCharts => currentCharts.map((chart, idx) => {
      if (idx === index) {
        return { ...chart, type };
      }
      return chart;
    }));
    setIndex(index);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const comparisonData = payload.length > 1 ? payload[1].payload : null; // Check if comparison data is available
      const comparisonKey = data.location ? 'location' : 'cause';

      if (comparisonData !== null){
        const proportion = ((data.totals / data.total_entries) * 100).toFixed(2);
        const comparisonProportion = comparisonData ? ((comparisonData.totals / comparisonData.total_entries) * 100).toFixed(2) : null;
        const totalsDifference = comparisonData ? (data.totals - comparisonData.totals) : null;

        return (
          <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
            <p><strong>{comparisonKey === "location" ? "Location:" : "Cause:"}</strong> {data[comparisonKey]}</p>
            <p><strong>Total entries (Year {data.year}):</strong> {data.total_entries}</p>
            <p><strong>Total {comparisonKey === "location" ? "Locations" : "Causes"} (Year {data.year}):</strong> {data.totals}</p>
            <p><strong>Proportion by Total Entries (Year {data.year}):</strong> {proportion}%</p>
            {comparisonData && (
              <>
                <p><strong>Total entries (Comparison Year {comparisonData.year}):</strong> {comparisonData.total_entries}</p>
                <p><strong>Total {comparisonKey === "location" ? "Locations" : "Causes"} (Comparison Year {comparisonData.year}):</strong> {comparisonData.totals}</p>
                <p><strong>Proportion by Total Entries (Comparison Year {comparisonData.year}):</strong> {comparisonProportion}%</p>
                <p><strong>Difference in Totals:</strong> {totalsDifference}</p>
              </>
            )}
          </div>
        );
      }
      else if (data.location || data.cause) {
        const proportion = ((data.totals / data.total_entries) * 100).toFixed(2);
        return (
          <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
            <p><strong>{data.location ? "Location:" : "Cause:"}</strong> {data.location ? data.location : data.cause}</p>
            <p><strong>Total entries:</strong> {data.total_entries}</p>
            <p><strong>Total Locations:</strong> {data.totals}</p>
            <p><strong>Proportion by Total Entries:</strong> {proportion}%</p>
          </div>
        );
      } else {
        // For other timelines
        const locations = data.proportions?.location || [];
        const sources = data.proportions?.source || [];
        const causes = data.proportions?.cause || [];

        return (
          <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
            <p><strong>Total Entries:</strong> {data.totals}</p>
            {data.start_date && <p>Start date: {data.start_date}</p>}
            {data.end_date && <p>End date: {data.end_date}</p>}
            <p><strong>Locations:</strong></p>
            {locations.length > 0 ? (
              locations.map((loc) => (
                <p key={loc.name}>{loc.name}: {loc.percentage}</p>
              ))
            ) : (
              <p>No location data available.</p>
            )}
            <p><strong>Sources:</strong></p>
            {sources.length > 0 ? (
              sources.map((src) => (
                <p key={src.name}>{src.name}: {src.percentage}</p>
              ))
            ) : (
              <p>No source data available.</p>
            )}
            <p><strong>Causes:</strong></p>
            {causes.length > 0 ? (
              causes.map((cause) => (
                <p key={cause.name}>{cause.name}: {cause.percentage}</p>
              ))
            ) : (
              <p>No cause data available.</p>
            )}
          </div>
        );
      }
    }

    return null;
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  // const [page, setPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1); 
  const handlePageChange = async (chart, increment, index) => {
    const newPage = chart.page + increment;
    setCharts(currentCharts => currentCharts.map((currentChart, idx) => {
      if (currentChart === chart) {
        return { ...currentChart, page: newPage };
      }
      return currentChart;
    }));
    setIndex(index);
    // await timeLine(index);
  };

  const CustomizedRectangle = (props) => {
    const { formattedGraphicalItems } = props;
    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];

    if (!firstSeries || !secondSeries) return null;

    const comparisonKey = firstSeries.props.points[0].payload.location ? 'location' : 'cause';
    return (
      <>
        {firstSeries.props.points.map((firstSeriesPoint, index) => {
          const secondSeriesPoint = secondSeries.props.points.find(
            (point) => point.payload[comparisonKey] === firstSeriesPoint.payload[comparisonKey]
          ) || { x: firstSeriesPoint.x, y: 0 };

          const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;
          return (
            <Rectangle
              key={firstSeriesPoint.payload.location}
              width={10}
              height={Math.abs(yDifference)}
              x={secondSeriesPoint.x - 5}
              y={yDifference > 0 ? secondSeriesPoint.y : secondSeriesPoint.y + yDifference}
              fill={yDifference > 0 ? 'red' : 'green'}
            />
          );
        })}
      </>
    );
  };

  const dataKeyMapping = {
    annual: { xKey: 'location', yKey: 'totals' },
    quarterly: { xKey: 'quarter', yKey: 'totals' },
    custom: { xKey: 'name', yKey: 'totals' },
    '13periods': { xKey: 'period', yKey: 'totals' },
  };

  const renderLineChart = (chart, index) => {

    const { xKey, yKey } = dataKeyMapping[chart.filters.timelineType];
    const comparisonData = chart.data.comparisonTotals ? chart.data.comparisonTotals : [];
    const chartData = chart.filters.timelineType === "annual" ? chart.data.totals : chart.data;

    return (
    <Box>
      <LineChart width={500} height={300} data={chartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        style={{
          border: '1px solid black',
          borderRadius: '8px',
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={chart.element === "cause" ? chart.element : xKey} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={(value, entry) => {
          if (entry.dataKey === yKey) return `${chart.year}`;
          if (entry.dataKey === 'comparisonTotals') return `${chart.comparisonYear}`;
          return value;
        }} />
        <Line type="monotone" dataKey={yKey} stroke="#8884d8" activeDot={{ r: 8 }} />
        {comparisonData.length > 0 && (
          <>
          <Line type="monotone" dataKey={yKey} data={comparisonData} stroke="#82ca9d" activeDot={{ r: 8 }} />
          <Customized component={CustomizedRectangle} />
          </>
        )}
      </LineChart>

      <Box>
        {chart.filters.timelineType === 'annual' && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
            <Box>
              <Button disabled={chart.page === 1} onClick={() => handlePageChange(chart, -1, index)}>
                Previous
              </Button>
              <Button disabled={chart.page === chart.totalPages} onClick={() => handlePageChange(chart, 1, index)}>
                Next
              </Button>
            </Box>
            <Box>
              <Button
                variant={chart.element === 'location' ? 'contained' : 'outlined'}
                onClick={() => handleElementChange(index, 'location')}
                sx={{ marginLeft: 2 }}
              >
                Location
              </Button>
              <Button
                variant={chart.element === 'cause' ? 'contained' : 'outlined'}
                onClick={() => handleElementChange(index, 'cause')}
                sx={{ marginLeft: 2 }}
              >
                Cause
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )}


  const renderPieChart = (chart, index) => {
    if (!chart.data || chart.data.length === 0) {
      return <p>No data available</p>;
    }

    let chartData = chart.filters.timelineType === 'annual' ? chart.data.totals : chart.data;
    let dataKey = "totals";
    let label = "";
    if(chart.filters.timelineType === 'annual'){
      label = ({ location }) => `${location}`;
    }else if(chart.filters.timelineType === 'quarterly'){
      label = ({ quarter }) => `${quarter}`;
    }else if(chart.filters.timelineType === '13periods'){
      label = ({ period }) => `${period}`;
    }

    // Ensure chartData is an array
    if (!Array.isArray(chartData)) {
      //console.error('Expected chartData to be an array but got:', chartData);
      return <p>Loading...</p>;
    }
    return (
      <Box>
      <PieChart width={400} height={300} style={{
        border: '1px solid black',
        borderRadius: '8px',
      }}>
        <Pie
          dataKey={dataKey}
          isAnimationActive={true}
          data={chartData}
          cx={200}
          cy={200}
          outerRadius={80}
          fill="#8884d8"
          label={label}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
      {chart.filters.timelineType === 'annual' && (
        <Box>
          <Button disabled={chart.page === 1} onClick={() => handlePageChange(chart, -1, index)}>
            Previous
          </Button>
          <Button disabled={chart.page === chart.totalPages} onClick={() => handlePageChange(chart, 1, index)}>
            Next
          </Button>
        </Box>
      )}
      </Box>
    );
  };

  const renderBarChart = (chart, index) => {
    const { xKey, yKey } = dataKeyMapping[chart.filters.timelineType];
    let chartData = chart.filters.timelineType === "annual" ? chart.data.totals : chart.data;

    return (
      <Box>
        <BarChart width={500} height={300} data={chartData} style={{
          border: '1px solid black',
          borderRadius: '8px',
        }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xKey} />
          <YAxis />
          <Legend />
          <Bar dataKey={yKey} fill="#8884d8" />
          <Tooltip content={<CustomTooltip />} />
        </BarChart>
        {chart.filters.timelineType === 'annual' && (
          <Box>
            <Button disabled={chart.page === 1} onClick={() => handlePageChange(chart, -1, index)}>
              Previous
            </Button>
            <Button disabled={chart.page === chart.totalPages} onClick={() => handlePageChange(chart, 1, index)}>
              Next
            </Button>
          </Box>
        )}
      </Box>
    );
  } 
  
  // Function to generate an array of years from 2018 to the current year
  const generateYearOptions = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
    return years;
  };

  // Render the line chart using recharts
  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <Box component="main" sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        paddingBottom: '100px',
      }}>
        <DrawerHeader />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" component="h1" sx={{ fontSize: '25px', padding: 2 }}>
            Visualization
          </Typography>
          <Box>
            <Button
              variant="contained"
              color='primary'
              startIcon={<img src={Add} alt="Add" />}
              onClick={addNewChart}
              sx={{
                borderRadius: 4, marginRight: 3, maxHeight: '35px',
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              }}
            >
            </Button>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={exportChartToPDF}
              sx={{
                backgroundColor: '#182355', borderRadius: 4, marginRight: 3, maxHeight: '35px',
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              }}
            >
            </Button>
          </Box>
        </Box>

        {charts.map((chart, index) => (
          <Box key={index} sx={{ padding: 2, display: 'flex', justifyContent: 'space-around' }}>
            {tableHeaders.map((header) => (
              <FormControl
                required
                variant="outlined"
                fullWidth
                sx={{
                  width: '15%',
                  height: 'auto',
                  backgroundColor: '#E3E8F7',
                  borderRadius: '20px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                }}>
                <Select
                  displayEmpty
                  value={chart.filters[header.id]}
                  onChange={(e) => handleFilterChange(index, header.id, e.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  renderValue={
                    chart.filters[header.id] !== '' ? undefined : () => <span>{header.id}</span>
                  }
                  sx={{ padding: 0 }}>
                  <MenuItem value="">
                    <em>{header.id}</em>
                  </MenuItem>
                  {filterOptions[header.id]?.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
          </Box>
        ))}
        <ThemeProvider theme={theme}>
        <Box id="recharts-container" sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          {charts.map((chart, index) => (
            <Box key={index} sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              width: { xs: '100%', md: '50%' }, 
              marginTop: 7, 
              '&:nth-of-type(2)': {
                  width: { xs: '75%', sm: '50%' }
              } 
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 1 }}>
                {charts.length > 1 &&
                  <Button
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={() => deleteChart(index)}
                    sx={{
                      backgroundColor: '#CE442C', color: 'white', borderRadius: 4, width: '30px',
                      '& .MuiButton-startIcon': {
                        margin: 0,
                      },
                      '&:hover': {
                        backgroundColor: 'red',
                        border: 'none'
                      },
                    }}
                  >
                  </Button>
                }
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[index].annual}
                        onChange={(event) => handleChange(index, event)}
                        name="annual"
                      />
                    }
                    label="Annual"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[index].quarterly}
                        onChange={(event) => handleChange(index, event)}
                        name="quarterly"
                      />
                    }
                    label="Quarterly"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[index]['13periods']}
                        onChange={(event) => handleChange(index, event)}
                        name="13periods"
                      />
                    }
                    label="13 periods"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[index].custom}
                        onChange={(event) => handleChange(index, event)}
                        name="custom"
                      />
                    }
                    label="Custom"
                  />
                </FormGroup>
                {checked[index].custom && (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 1
                  }}>
                    <TextField
                      label="Start Date"
                      type="date"
                      name="startPeriod"
                      value={customDates[index].startPeriod}
                      onChange={(event) => handleDateChange(index, event)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="End Date"
                      type="date"
                      name="endPeriod"
                      value={customDates[index].endPeriod}
                      onChange={(event) => handleDateChange(index, event)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: 1 }}
                    />
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={() => onClickCustom(index)}
                      sx={{
                        backgroundColor: '#182355', borderRadius: 4, width: '30px', marginTop: 1,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                        },
                      }}
                    >
                    </Button>
                  </Box>
                )}
                {(checked[index].annual || checked[index].quarterly || checked[index]['13periods']) && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 1 }}>
                    <FormControl>
                      <InputLabel id={`year-select-label-${index}`}>Year</InputLabel>
                      <Select
                        labelId={`year-select-label-${index}`}
                        id={`year-select-${index}`}
                        value={chart.year}
                        label="Year"
                        onChange={(event) => handleYearChange(index, event)}
                      >
                        {generateYearOptions(2018).map(year => (
                          <MenuItem key={year} value={year}>{year}</MenuItem>
                        ))}                      
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {checked[index].annual && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 1 }}>
                    <FormControl>
                      <Select
                        labelId={`year-select-label-${index}`}
                        id={`year-select-${index}`}
                        value={chart.comparisonYear}
                        label="Comparison Year"
                        onChange={(event) => handleComparisonYearChange(index, event)}
                        displayEmpty
                        renderValue={
                          chart.comparisonYear !== null ? undefined : () => <span>Select Year</span>
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {generateYearOptions(2018).map(year => (
                          <MenuItem key={year} value={year}>{year}</MenuItem>
                        ))}                      
                        </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>
              <ResponsiveContainer width="75%" height={300}>
                <FormControlLabel
                  control={<Checkbox checked={chart.type === 'pie'} onChange={() => handleChangeChartType(index, 'pie')} />}
                  label="Pie Chart"
                />
                <FormControlLabel
                  control={<Checkbox checked={chart.type === 'bar'} onChange={() => handleChangeChartType(index, 'bar')} />}
                  label="Bar Chart"
                />
                <FormControlLabel
                  control={<Checkbox checked={chart.type === 'line'} onChange={() => handleChangeChartType(index, 'line')} />}
                  label="Line Chart"
                />
                {chart.type === 'line' && renderLineChart(chart, index)}
                {chart.type === 'pie' && renderPieChart(chart, index)}
                {chart.type === 'bar' && renderBarChart(chart, index)}
              </ResponsiveContainer>
            </Box>
          ))}
        </Box>
        </ThemeProvider>
      </Box>
    );
  }
}
export default Visualization;
