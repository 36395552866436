import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios'; 

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token'));

  const refreshToken = async () => {
    const response = await axios.post(`http://localhost:8011/api/refresh-token`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const newToken = response.data.token;
    localStorage.setItem('token', newToken);
    setToken(newToken);
  };

  useEffect(() => {
    const checkToken = async () => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setIsLoggedIn(true);
        setToken(storedToken);

        // Schedule a token refresh every 2 hours
        const twoHoursInMs = 2 * 60 * 60 * 1000;
          setTimeout(async () => {
            await refreshToken();
          }, twoHoursInMs);       
        setIsLoggedIn(true);
      }
    };

    checkToken();
  }, []);

  const login = (newToken) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
    setIsLoggedIn(true);
  };

  // Function to log out
  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setIsLoggedIn(false);
  };
  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, refreshToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use the auth context
export const useAuth = () => useContext(AuthContext);
