import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LoginPage from './components/Login';
import RegisterPage from './components/Register';
import EntriesPage from './components/Entries';
import AddEntryPage from './components/AddEntry';
import DetailsEntryPage from './components/EntryDetails';
import VisualizationPage from './components/Visualization';
import UserManagementPage from './components/UserManagement';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ProtectedRoute from './middlewares/ProtectedRoute';
import { useAuth } from './context/authContext';

function App() {

  const user = JSON.parse(localStorage.getItem('user'));
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const {isLoggedIn} = useAuth(); 

  const handleMenuClick = () => {
    if (isLoggedIn) { 
      setSidebarOpen(!isSidebarOpen);
    };
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Router>
      <Header bgColor={isLoggedIn ? 'white' : '#E3E8F7'} showMenu={isLoggedIn} onMenuClick={handleMenuClick} />
      <Box sx={{ display: 'flex' }}>
        {isLoggedIn && <Sidebar isOpen={isSidebarOpen} isCollapsed={!isSidebarOpen} onCollapseClick={handleMenuClick} />}

        <Routes>
          <Route path="/login" element={!isLoggedIn ? <LoginPage /> : <Navigate to="/entries" />} />
          <Route path="/register" element={!isLoggedIn ? <RegisterPage /> : <Navigate to="/entries" />} />
          <Route path="/entries" element={isLoggedIn ? <EntriesPage /> : <Navigate to="/login" />} />
          <Route path="/add-entry" element={
            <ProtectedRoute user={user}>
              <AddEntryPage />
            </ProtectedRoute>
          } />
          <Route path="/entry-details/:id" element={isLoggedIn ? <DetailsEntryPage /> : <Navigate to="/login" />} />
          <Route path="/graphics" element={isLoggedIn ? <VisualizationPage /> : <Navigate to="/login" />} />
          <Route path="/user-panel" element={isLoggedIn ? <UserManagementPage /> : <Navigate to="/login" />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
