import Box from '@mui/material/Box';
import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Typography, Button, Grid } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '../context/api';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const EntryForm = ({ mode, setMode, data, onDataChange, onSubmit, onCancel, updates = [], updatesData = [], onDataUpdatesChange, onSubmitUpdates, selectedFiles, setSelectedFiles }) => {

  const user = JSON.parse(localStorage.getItem('user'));
  const [openDialog, setOpenDialog] = useState(false);
  const isDisabled = mode === 'view';
  const [classificationData, setClassificationData] = useState([
    { id: 'location', label: 'Location', xs: 4, options: ['Option 1', 'Option 2']},
    { id: 'source', label: 'Source', xs: 4, options: ['Auto', 'General Liability', 'Workers Comp'] },
    { id: 'cause', label: 'Cause', xs: 4, options: ['Option 1', 'Option 2']},
    { id: 'date', label: 'Date', xs: 4, type: 'date' },
    { id: 'injured', label: 'Injured', xs: 4, type: 'text' },
    { id: 'reference', label: 'Reference', xs: 4, type: 'text' },
  ]);

  const details = [
    { id: 'case', label: 'Case Status', xs: 4, options: ['Open', 'Closed', 'Re-open'] },
    { id: 'legal', label: 'Legal Status', xs: 4, options: ['No Legal Status', 'Legal Respresentation', 'Law suit'] },
    { id: 'incurred', label: 'Incurred', xs: 4 },
    { id: 'loss', label: 'Lossdays', xs: 4 },
    { id: 'restrict', label: 'Restriction Days', xs: 4 },
    { id: 'outpayment', label: 'Outpayment', xs: 4 },
    { id: 'deductible', label: 'Deductible', xs: 4 },
  ];

  const getData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`/data`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        const fetchedData = response.data;
        const updatedClassificationData = classificationData.map(item => {
          if (item.id === 'location' || item.id === 'cause') {
            const options = fetchedData
              .filter(i => i.type === item.id)
              .map(i => i.value)
              .sort((a, b) => a.localeCompare(b)); // Sorting alphabetically

            return { ...item, options: options };
          }
          return item;
        });

        setClassificationData(updatedClassificationData);
      }
    } catch (error) {
      console.error('An error occurred during the file upload:', error);
    }
  }

  if (data['date'] && data['date'] !== '') {
    const date = new Date(data['date']);
    data['date'] = date.toISOString().split('T')[0];
  }

  // Files
  const fileInput = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const [files, setFiles] = useState([]);
  const onUpload = async () => {
    if (selectedFiles.length) {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      selectedFiles.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      try {
        const response = await api.post(`/file/${data['id']}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 201) {
          fetchFiles();
        }
      } catch (error) {
        console.error('An error occurred during the file upload:', error);
      }
    } else {
      console.log('No files selected to upload.');
    }
  };

  const onDownload = async (file) => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`/file/download/${file.id}`, {
        responseType: 'blob',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      // Create a new Blob object using the response data of the onload object
      const blob = new Blob([response.data], { type: file.mime_type });

      // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
      const a = document.createElement("a");
      a.style = "display: none";
      document.body.appendChild(a);
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = file.name; // the filename you want
      a.click();
      // Clean up and remove the link
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      console.error("Error during file download:", error);
    }
  }

  const fetchFiles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get(`/file/${data['id']}`, { headers: { 'Authorization': `Bearer ${token}` } });
      setFiles(response.data); // Update state with fetched files
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const [dataVideo, setDataVideo] = useState({
      video: false,  
  });
  
  const handleCheckboxChange = (event) => {
      const { checked } = event.target;
      onDataChange("video", checked);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (data['id']) {
      fetchFiles(); 
    }
  }, [data['id']]);

  useEffect(() => {
    getData();
    console.log(data)
  }, []); 

  const areRequiredFieldsFilled = () => {
    const requiredFields = [
      'location',
      'source',
      'case',
      'date',
      'cause',
      'legal',
      'reference',
      //'injured', 
      //'incurred',
      //'loss', 
      //'restrict',
      //'outpayment',
      //'deductible'
    ];
    return requiredFields.every((field) => data[field] && data[field].trim() !== '');
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, backgroundColor: 'white', paddingLeft: 3, paddingRight: 3 }}>
      <DrawerHeader />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 3 }}>
          <form onSubmit={onSubmit} style={{ padding: '1rem' }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <h1>{mode === 'add' ? "New entry" : (mode === 'view' ? "Entry details" : "Edit Entry")}</h1>
              </Grid>
              {/* Classification Data */}
              <Grid item xs={12}>
                <h2 style={{ fontWeight: 'bold' }}>Classification Data</h2>
              </Grid>
              {classificationData.map((field) => (
                <Grid item xs={field.xs} key={field.id}>
                  <Typography variant="h5" component="h1"
                    sx={{ fontSize: '17px', flexGrow: 1, margin: '0 0 10px 10px', }}>
                    {field.label}
                  </Typography>
                  {field.type  ? (
                    <TextField
                      required
                      type={field.type}
                      variant="outlined"
                      fullWidth
                      disabled={isDisabled}
                      value={data[field.id]}
                      sx={{
                        backgroundColor: '#E3E8F7',
                        borderRadius: '20px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}
                      onChange={(e) => onDataChange(field.id, e.target.value)}
                    />
                  ) : (
                    <FormControl
                      required
                      variant="outlined"
                      fullWidth
                      sx={{
                        backgroundColor: '#E3E8F7',
                        borderRadius: '20px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}>
                      <Select
                        value={data[field.id]}
                        onChange={(e) => onDataChange(field.id, e.target.value)}
                        disabled={isDisabled}
                      >
                        {field.options.map((option) => (
                          <MenuItem value={option} key={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              ))
              }

              {/* Details */}
              <Grid item xs={12}>
                <h2>Details</h2>
              </Grid>
              <Grid item xs={4} key={"video"} sx={{
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',    
                justifyContent: 'center',
                height: '100%'           
              }}>
                <Typography variant="h5" component="h1"
                  sx={{ fontSize: '17px', flexGrow: 1, }}>
                  Video
                </Typography>
                <FormControlLabel
                    disabled={isDisabled}
                    // value={data["video"]}
                    control={
                        <Checkbox
                            checked={data["video"]}
                            onChange={handleCheckboxChange}
                            name="video"
                            color="primary"
                        />
                    }
                    sx={{
                        margin: 0,
                    }}
                />
              </Grid>
              {details.map((field) => (
                <Grid item xs={field.xs} key={field.id}>
                  <Typography variant="h5" component="h1"
                    sx={{ fontSize: '17px', flexGrow: 1, margin: '0 0 10px 10px', }}>
                    {field.label}
                  </Typography>
                {field.id !== "case" && field.id !== "legal" ? (
                  <TextField
                    // type={field.id !== "reference" && field.id !== "injured" ? "number" : "text"}
                    type="number"
                    variant="outlined"
                    fullWidth
                    disabled={isDisabled}
                    value={data[field.id]}
                    sx={{
                      backgroundColor: '#E3E8F7',
                      borderRadius: '20px',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                      },
                    }}
                    onChange={(e) => onDataChange(field.id, e.target.value)}
                  />
                  ) : (
                    <FormControl
                      required
                      variant="outlined"
                      fullWidth
                      sx={{
                        backgroundColor: '#E3E8F7',
                        borderRadius: '20px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}>
                      <Select
                        value={data[field.id]}
                        onChange={(e) => onDataChange(field.id, e.target.value)}
                        disabled={isDisabled}
                      >
                        {field.options.map((option) => (
                          <MenuItem value={option} key={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              ))
              }
              <Grid item xs={12}>
                <Typography variant="h5" component="h1"
                  sx={{
                    fontSize: '17px', flexGrow: 1, margin: '50px 0 10px 10px',
                  }}>
                  Short Description
                </Typography>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={6}
                  variant="outlined"
                  disabled={isDisabled}
                  value={data['shortdesc']}
                  onChange={(e) => onDataChange('shortdesc', e.target.value)}
                  style={{
                    backgroundColor: '#E3E8F7',
                    border: 'none',
                    width: '99%',
                    borderRadius: '20px',
                    padding: '10px',
                    fontSize: '15px'
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h1"
                  sx={{ margin: '50px 0 10px 10px', }}>
                  Updates
                </Typography>
              </Grid>
              {mode !== 'view' && (
                <>
                  <Grid item xs={4}>
                    <TextField
                      type="date"
                      variant="outlined"
                      fullWidth
                      value={updates.date}
                      sx={{
                        backgroundColor: '#E3E8F7',
                        borderRadius: '20px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}
                      onChange={(e) => onDataUpdatesChange('date', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      value={updates.update}
                      sx={{
                        backgroundColor: '#E3E8F7',
                        borderRadius: '20px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}
                      onChange={(e) => onDataUpdatesChange('update', e.target.value)}
                    />
                  </Grid>
                </>
              )}
              {updatesData.length > 0 && (
                updatesData.map((update) => (
                  <React.Fragment key={update.id}>
                    <Grid item xs={4}>
                      <TextField
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={update.date}
                        disabled={true}
                        sx={{
                          backgroundColor: '#E3E8F7',
                          borderRadius: '20px',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={update.update}
                        disabled={true}
                        sx={{
                          backgroundColor: '#E3E8F7',
                          borderRadius: '20px',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                )))}
              {mode === 'view' ? (
                <>
                  <Grid item xs={12}>
                    <h2>Additional Info</h2>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h5" component="h1"
                      sx={{ marginLeft: '0.5rem', paddingTop: '5px' }}>
                      Files
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {files.map((file) => (
                      <Button
                        key={file.name}
                        variant="contained"
                        onClick={() => onDownload(file)}
                        sx={{
                          m: 1, // margin
                          borderRadius: 2, // border radius
                          backgroundColor: '#182355'
                        }}
                      >
                        {file.name}
                      </Button>
                    ))}
                  </Grid>
                </>
              ) : (
                <>
                  {mode !== 'add' &&
                    <Grid item xs={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<UploadIcon />}
                        onClick={handleOpenDialog}
                        style={{ marginLeft: '0.5rem' }}
                      >
                        Add update
                      </Button>
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <h2>Additional Info</h2>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h5" component="h1"
                      sx={{ marginLeft: '0.5rem', paddingTop: '5px' }}>
                      Files
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <input
                      type="file"
                      multiple
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      ref={fileInput}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<SaveIcon />}
                      onClick={() => fileInput.current.click()}
                    >
                      Select file
                    </Button>
                    {mode === 'edit' &&
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<UploadIcon />}
                        onClick={onUpload}
                        sx={{ marginLeft: 1 }}
                      >
                        Upload file
                      </Button>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {files.map((file) => (
                      <Button
                        key={file.name}
                        variant="contained"
                        onClick={() => onDownload(file)}
                        sx={{
                          m: 1, // margin
                          borderRadius: 2, // border radius
                          backgroundColor: '#182355'
                        }}
                      >
                        {file.name}
                      </Button>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color='primary'
                      disabled={mode === 'add' && !areRequiredFieldsFilled()}
                      startIcon={<CheckIcon />}
                      onClick={onSubmit}
                      sx={{
                        borderRadius: '30px', backgroundColor: 'red', color: 'white', border: 'none',
                        '& .MuiButton-startIcon': {
                          margin: 0,
                        },
                      }}
                    >
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Upload"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to upload the data?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={() => { onSubmitUpdates(); handleCloseDialog(); }} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        {mode === 'view' ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', gap: 1, p: 3, paddingTop: 9 }}>
          {
            user.rol === 'EDITOR' && (
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={() => setMode('edit')}
              sx={{
                marginLeft: '0.5rem', borderRadius: '30px', backgroundColor: '#182355', color: 'white', border: 'none',
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
                '&:hover': {
                  backgroundColor: 'darkblue',
                  border: 'none'
                },
              }}
            >
            </Button>
            )
          }
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={onCancel}
              sx={{
                marginLeft: '0.5rem', borderRadius: '30px', backgroundColor: 'transparent', color: '#182355', border: '1px solid #182355',
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
                '&:hover': {
                  backgroundColor: '#eee',
                  border: '1px solid black'
                },
              }}
            >
            </Button>
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1, p: 3, paddingTop: 9 }}>
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={onCancel}
              sx={{
                marginLeft: '0.5rem', borderRadius: '30px', backgroundColor: '#CE442C', color: 'white', border: 'none',
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
                '&:hover': {
                  backgroundColor: 'red',
                  border: 'none'
                },
              }}
            >
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EntryForm;
