import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Box, Alert } from '@mui/material';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

function UserForm({ title, showNameField, onFormSubmit, onEmailChange, onPasswordChange, onNameChange, email, message, password, name }) {
  return (
    <Container component="main" maxWidth="xs" sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
        }}>
      <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold' }}> 
        {title}
      </Typography>
      <Box component="form" onSubmit={onFormSubmit} noValidate>
        {showNameField && (
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoFocus
            value={name}
            onChange={onNameChange}
            sx={{ 
                borderRadius: 4,
                '& label.Mui-focused': {
                  color: '#182355', 
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 4,
                  '& fieldset': {
                    borderColor: '#eee', 
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main', 
                  },
                  '&.Mui-focused fieldset': {
                    borderRadius: 4,
                    borderColor: '#182355',
                  },
                },
                backgroundColor: 'white',
            }}
          />
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={onEmailChange}
          sx={{ 
                borderRadius: 4,
                '& label.Mui-focused': {
                  color: '#182355', 
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 4,
                  '& fieldset': {
                    borderColor: '#eee', 
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main', 
                  },
                  '&.Mui-focused fieldset': {
                    borderRadius: 4,
                    borderColor: '#182355',
                  },
                },
                backgroundColor: 'white',
            }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={onPasswordChange}
          sx={{ 
                borderRadius: 4,
                '& label.Mui-focused': {
                  color: '#182355', 
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 4,
                  '& fieldset': {
                    borderColor: '#eee', 
                  },
                  '&:hover fieldset': {
                    borderColor: 'primary.main', 
                  },
                  '&.Mui-focused fieldset': {
                    borderRadius: 4,
                    borderColor: '#182355',
                  },
                  '&.Mui-filled fieldset': {
                    backgroundColor: 'white',
                  },
                },
                backgroundColor: 'white',
            }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ 
            mt: 3, mb: 2,
            backgroundColor: '#182355',
          }}
        >
          {title}
        </Button>
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {title === "Log in" ? (
              <Link href="/register" variant="body2" sx={{ color: '#182355', mt: 2 }}>
                {"Don't have an account? Sign Up"}
              </Link>
            ) : (
              <Link href="/login" variant="body2" sx={{ color: '#182355', mt: 2 }}>
                {"Already have an account? Sign in"}
              </Link>
            )}
        </Container>
        <Box component="form" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            '& .MuiTextField-root': { m: 1, width: '25ch' }, 
            marginTop: 2
        }}>
            {message && <Alert severity="error">{message}</Alert>}
        </Box>
      </Box>
    </Container>
  );
}

export default UserForm;
