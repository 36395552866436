import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import api from '../context/api';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

function EntriesPage() {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    const fetchEntries = async () => {
      try {
        const response = await api.get(`/${user.id}/entry`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          const formattedEntries = response.data.map(entry => ({
            ...entry,
            date: formatDate(entry.date) // Formatting the date directly here
          }));
          setEntries(formattedEntries);
          setLoading(false);
          setFilteredEntries(response.data);
          const options = {
            location: [...new Set(response.data.map(item => item.location))],
            source: [...new Set(response.data.map(item => item.source))],
            cause: [...new Set(response.data.map(item => item.cause))],
            date: [...new Set(response.data.map(item => formatDate(item.date)))],
            injured: [...new Set(response.data.map(item => item.injured))],
            reference: [...new Set(response.data.map(item => item.reference))],

          };
          setFilterOptions(options);
        }

      } catch (error) {
        // Handle error here, such as showing an error message to the user
        console.error("An error occurred:", error.response || error);
      }
    };

    if (user && token) {
      fetchEntries();
    }
  }, [])

  // State for pagination and rows per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Change rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableHeaders = [
    { id: 'location', label: 'Location' },
    { id: 'source', label: 'Source' },
    { id: 'cause', label: 'Cause' },
    { id: 'date', label: 'Date' },
    { id: 'injured', label: 'Injured' },
    { id: 'reference', label: 'Reference' },
    { id: 'details', label: 'Details' }
  ];

  const [filterOptions, setFilterOptions] = useState({
    location: [],
    source: [],
    cause: [],
    date: [],
    injured: [],
    reference: [],
  });
  
  const [selectedFilters, setSelectedFilters] = useState({
    location: '',
    source: '',
    cause: '',
    date: '',
    injured: '',
    reference: '',
  });

  const [filteredEntries, setFilteredEntries] = useState([]); // Stores entries filtered based on user input

  useEffect(() => {
    applyFilters();
  }, [selectedFilters, entries]); 

  useEffect(() => {
    setPage(0); // Reset pagination when filteredEntries change
  }, [filteredEntries, rowsPerPage]);

  const applyFilters = () => {
    let newFilteredData = entries;
    Object.entries(selectedFilters).forEach(([key, value]) => {
      if (value !== '') {
        newFilteredData = newFilteredData.filter(item => item[key] === value);
      }
    });
    setFilteredEntries(newFilteredData);
  };

  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleDateString('en-US', {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  const handleFilterChange = (filterName, value) => {
    setSelectedFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
  };

  // Search
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get('/search',
        {
          params: { term: searchTerm },
          headers: { 'Authorization': `Bearer ${token}` }
        });
      if (response.status === 200) {
        setEntries(response.data);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // react-pdf
  const styles = StyleSheet.create({
    page: { flexDirection: 'column', backgroundColor: '#FFFFFF', padding: 10, fontSize: 8 },
    section: { margin: 10, padding: 5, flexGrow: 1 },
    table: { display: "table", width: "auto" },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableColHeader: { width: "15%", borderStyle: "solid", borderWidth: 1, backgroundColor: '#E3E8F7', },
    tableCol: { width: "15%", borderStyle: "solid", borderWidth: 1, padding: 5 },
    tableCellHeader: { margin: "auto", fontSize: 10, fontWeight: 500 },
    tableCell: { margin: "auto", fontSize: 8 }
  });

  const TableDownload = ({ entries = [], tableHeaders = [] }) => {
    tableHeaders = tableHeaders.slice(0, -1);
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              {tableHeaders.map((header, index) => (
                <View style={styles.tableColHeader} key={index}>
                  <Text style={styles.tableCellHeader}>{header.label}</Text>
                </View>
              ))}
            </View>
            {/* Table Rows */}
            {entries.map((entry, index) => {
              const dateObject = new Date(entry.date);
              const formattedDate = dateObject.toLocaleDateString('en-US', {
                timeZone: 'UTC',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              });
              return (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.tableCol}>{entry.location}</Text>
                  <Text style={styles.tableCol}>{entry.source}</Text>
                  <Text style={styles.tableCol}>{entry.cause}</Text>
                  <Text style={styles.tableCol}>{formattedDate}</Text>
                  <Text style={styles.tableCol}>{entry.case}</Text>
                  <Text style={styles.tableCol}>{entry.legal}</Text>
                </View>
              )
            })}
          </View>
        </Page>
      </Document>
    )
  };

  // Assuming you have an array of entries to display
  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <DrawerHeader />
      {loading && entries ? (
        <p>Loading...</p>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
            {/* Title */}
            <Typography variant="h5" component="h1" sx={{ fontSize: '25px', flexGrow: 1 }}>
              All entries
            </Typography>

            {/* Search Field */}
            <Box sx={{ flexGrow: 2, maxWidth: '300px' }}>
              <TextField
                id="search"
                placeholder="Search"
                type="search"
                variant="outlined"
                size="small"
                fullWidth
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSearch();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Download Button */}
            <PDFDownloadLink document={<TableDownload entries={filteredEntries} tableHeaders={tableHeaders} />} fileName="table-data.pdf">
              {({ blob, url, loading, error }) =>
                loading ? (
                  'Loading document...'
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    sx={{
                      backgroundColor: '#182355', borderRadius: 4, marginLeft: 3, maxHeight: '35px',
                      '& .MuiButton-startIcon': {
                        margin: 0,
                      },
                    }}
                  >
                  </Button>

                )
              }
            </PDFDownloadLink>
          </Box>
          <TableContainer sx={{ maxHeight: '100%' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      padding: 1, // Reduces cell padding, adjust as needed
                      width: 'auto', // Adjust based on your content needs
                      '&:not(:last-child)': {
                        mr: 2 // Adds margin to the right of each cell except the last one
                      }
                    }}
                  >
                    <FormControl
                      required
                      variant="outlined"
                      fullWidth
                      size='small'
                      sx={{
                        height: 'auto',
                        backgroundColor: '#E3E8F7',
                        borderRadius: '20px',
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            border: 'none',
                          },
                          '&:hover fieldset': {
                            border: 'none',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none',
                          },
                        },
                      }}>
                      <Select
                        displayEmpty
                        value={selectedFilters[header.id] || ''}
                        onChange={(e) => handleFilterChange(header.id, e.target.value)}
                        inputProps={{ 'aria-label': 'Without label' }}
                        renderValue={
                          selectedFilters[header.id] !== '' ? undefined : () => <span>{header.label}</span>
                        }
                        sx={{ padding: 0, width: '100%' }}>  {/* Adjust width as necessary */}
                        <MenuItem value="">
                          <em>{header.label}</em>
                        </MenuItem>
                        {filterOptions[header.id]?.map((option) => (
                          <MenuItem value={option} key={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEntries
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {/* Replace with your actual data cells */}
                        <TableCell>{entry.location}</TableCell>
                        <TableCell>{entry.source}</TableCell>
                        <TableCell>{entry.cause}</TableCell>
                        <TableCell>{formatDate(entry.date)}</TableCell>
                        <TableCell>{entry.injured}</TableCell>
                        <TableCell>{entry.reference}</TableCell>
                        <TableCell>
                          <Button
                            component={NavLink}
                            to={`/entry-details/${entry.id}`}
                            variant="contained"
                            size="small"
                            sx={{
                              backgroundColor: 'red', color: 'white', borderRadius: 4,
                              '&:hover': {
                                backgroundColor: 'darkred',
                              },
                            }} >
                            More
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredEntries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Box>
  );
}

export default EntriesPage;

