import { Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar' 
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../assets/AppIcon.png'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import api from '../context/api';
import { useAuth } from '../context/authContext';

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({ bgColor, showMenu, onMenuClick }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {setIsLoggedIn} = useAuth(); 

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    // Call your API to handle logout if necessary
      try {
        const token = localStorage.getItem('token');
        await api.post('/logout', {}, {
        headers: {
            'Authorization': `Bearer ${token}`
            }
        });

        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsLoggedIn(false);
        handleClose();
        navigate('/login');
      } catch (error) {
        // Handle error here, such as showing an error message to the user
        console.error("An error occurred during loging out:", error.response);
      }

    //setIsLoggedIn(false);
    //setSidebarOpen(false);
  };

  return (
    <AppBar position="fixed" style={{ background: bgColor }} elevation={0}>
      <Toolbar>
        {showMenu && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onMenuClick}
            sx={{ mr: 2, color: 'black' }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <img src={Logo} alt="Incidence App Logo" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
        <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1, color: 'black'}}>
          Incidence App
        </Typography>

        {showMenu && (
            <>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon sx={{ color: 'black', fontSize: '40px' }}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
