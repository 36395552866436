import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserForm from './UserForm';
import { useAuth } from '../context/authContext';
import api from '../context/api'

function LoginPage() {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {setIsLoggedIn} = useAuth(); 
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
      try {
        const response = await api.post('/login', {
          email: email,
          password: password,
        });

        if(response.status === 200){
            setError(''); // Clear any previous errors
            // Assuming the token is in the response data with the key 'token'
            const token = response.data.token;
            const user = response.data.user;

            // Save the token to local storage
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setIsLoggedIn(!!token);

            navigate('/entries');
        } else {
            // Set error message
            console.log(response.message);
            setError('Unexpected response from the server.');
        }
      } catch (error) {
        console.log("Error")
        console.log(error.response.data.message)
        setError(error.response.data.message || 'There was an error retrieving the data');
      }
  };

  return (
    <UserForm
      title="Log in"
      showNameField={false}
      onFormSubmit={handleLogin}
      onEmailChange={(e) => setEmail(e.target.value)}
      onPasswordChange={(e) => setPassword(e.target.value)}
      email={email}
      message={error}
      password={password}
    />
  ); 
}

export default LoginPage;
