import React, { useState } from 'react';
import EntryForm from './EntryForm';
import api from '../context/api';
import { useNavigate } from 'react-router-dom';

const AddEntry = () => {
  // Initialize form state with empty values or default values
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [updates, setUpdates] = useState({
    date: '',
    update: ''
  });
  const [formData, setFormData] = useState({
    location: '',
    source: '',
    case: '',
    date: '',
    cause: '',
    legal: '',
    reference: '',
    injured: '',
    incurred: '',
    loss: '',
    restric: '',
    outpayment: '',
    deductible: '',
    shortdesc: '',
    video: false
  });

  const handleInputChange = (field, value) => {
    // Update the form data state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token'); 
    try {
      const response = await api.post(`/${user.id}/entry`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200 || response.status === 201) {
        const entryId = response.data.entry.id;
        if(updates.date && updates.update){
            const updateResponse = await api.post(`/entry/${entryId}/update`, updates, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                }
            });
            if(updateResponse.status !== 201){
                console.log(updateResponse.error);
            }
        }
          
        if(selectedFiles.length > 0){
            const fileData = new FormData();
            selectedFiles.forEach((file, index) => {
                fileData.append(`files[${index}]`, file);
            });
            const fileResponse = await api.post(`/file/${entryId}`, fileData, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            });
            if(fileResponse.status !== 201){
                console.log(fileResponse.error);
            }
        }

        navigate('/entries');
      }

    } catch (error) {
      console.error("An error occurred:", error.response || error);
    }
  };

    // Updates
  const handleUpdatesChange = (field, value) => {
    setUpdates((prevFormData) => ({
      ...prevFormData,
      [field]: value
    }));
  };
  const handleCancel = () => {
    // Handle the cancel action, such as resetting the form or navigating away
    navigate('/entries');
  };

  return (
    <EntryForm
      mode="add"
      data={formData}
      onDataChange={handleInputChange}
      onSubmit={handleSubmit}
      onCancel={handleCancel}

      updates={updates}
      onDataUpdatesChange={handleUpdatesChange}

      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
    />
  );
};

export default AddEntry;

