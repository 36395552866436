import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Divider, IconButton } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import Add from '../assets/add.png'; 
import Tab from '../assets/table_chart.png'; 
import Chart from '../assets/bid_landscape.png'; 

const drawerWidth = 260;
const collapsedWidth = 56;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: collapsedWidth,
  [theme.breakpoints.up('sm')]: {
    width: collapsedWidth,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const activeListItemStyle = (isCollapsed) => ({
  backgroundColor: 'white',
  color: 'black',
  borderRadius: isCollapsed ? '25%' : '0 25px 25px 0', 
  '& .MuiListItemIcon-root': {
    borderRadius: isCollapsed ? '25%' : '25px', 
  },
  '&:hover': {
    backgroundColor: 'white', 
    borderRadius: isCollapsed ? '25%' : '0 25px 25px 0', 
  },
});

function Sidebar({ isOpen, isCollapsed, onCollapseClick }) {
  return (
    <Drawer
      open={isOpen}
      variant="permanent"
        sx={{
            '& .MuiDrawer-paper': {
              width: isCollapsed ? collapsedWidth : drawerWidth,
              boxSizing: 'border-box',
              overflowX: 'hidden',
              backgroundColor: "#E3E8F7" 
            },
          }}>
      <DrawerHeader>
          <IconButton onClick={onCollapseClick}>
            <MenuIcon />
          </IconButton>
      </DrawerHeader>
      <Divider />
      <List 
          sx={(theme) => ({
            padding: isCollapsed ? '15px 7px 7px 7px' : '15px 15px 0 0', 
            '& .MuiListItem-root': {
              justifyContent: isCollapsed ? 'center' : 'flex-start', 
              '& .MuiListItemIcon-root': {
                minWidth: isCollapsed ? 'auto' : theme.spacing(5), 
              },
              '& .MuiListItemText-primary': {
                display: isCollapsed ? 'none' : 'block', 
              },
            },
          })}
        > 
        <ListItem button component={NavLink} to="/add-entry" sx={(theme) => ({
          '&.active': activeListItemStyle(isCollapsed)})}>
          <ListItemIcon>
           <img src={Add} alt="add" style={{
              borderRadius: '35%', 
              backgroundColor: 'black',
            }}/> 
          </ListItemIcon>
          { !isCollapsed && <ListItemText primary="Add entry" /> }
        </ListItem>
        <ListItem button component={NavLink} to="/entries" sx={(theme) => ({
          '&.active': activeListItemStyle(isCollapsed)})}>
          <ListItemIcon>
            <img src={Tab} alt="tab" style={{ color: 'black' }}/>
          </ListItemIcon>
          { !isCollapsed && <ListItemText primary="All entries" /> }
        </ListItem>
        <ListItem button component={NavLink} to="/graphics" sx={(theme) => ({
          '&.active': activeListItemStyle(isCollapsed)})}>
          <ListItemIcon>
            <img src={Chart} alt="chart" style={{ color: 'black' }}/>
          </ListItemIcon>
          { !isCollapsed && <ListItemText primary="Visualization" /> }
        </ListItem>
        <ListItem button component={NavLink} to="/user-panel" sx={(theme) => ({
          '&.active': activeListItemStyle(isCollapsed)})}>
          <ListItemIcon>
            <SettingsIcon sx={{ color: 'black', marginLeft: 0.5 }} />
          </ListItemIcon>
          { !isCollapsed && <ListItemText primary="User management" /> }
        </ListItem>
      </List>
    </Drawer>
  );
}

export default Sidebar;
