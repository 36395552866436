import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserForm from './UserForm';
import api from '../context/api'
import { useAuth } from '../context/authContext';

function RegisterPage() {
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const {setIsLoggedIn} = useAuth(); 
  const navigate = useNavigate();

  const handleRegister = async (event) => {
      event.preventDefault();

      try {
        const response = await api.post('/register', {
          name: name,
          email: email,
          password: password,
        });

        if(response.status === 201){
            setError(''); // Clear any previous errors
            // Assuming the token is in the response data with the key 'token'
            const token = response.data.token;
            const user = response.data.user;

            // Save the token to local storage
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setIsLoggedIn(!!token)

            navigate('/entries');
        } else {
            setError('Unexpected response from the server.');
        }
      } catch (error) {
        setError(error.response?.data?.message || 'An error occurred while changing the password.');
      }
  };

    return (
        <UserForm
          title="Register"
          showNameField={true}
          onFormSubmit={handleRegister}
          onEmailChange={(e) => setEmail(e.target.value)}
          onPasswordChange={(e) => setPassword(e.target.value)}
          onNameChange={(e) => setName(e.target.value)}
          email={email}
          password={password}
          message={error}
          name={name}
        />
    );
}

export default RegisterPage;
